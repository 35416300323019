import React from 'react'
import PropTypes from 'prop-types'

import View from './EpisodeRollView'

const EpisodeRoll = ({ title, episodes }) => {
  // Set the parameters for the Swiper
  const swiperProps = {
    grabCursor: true,
    slidesPerView: 5,
    spaceBetween: 20,
    pagination: {
      el: `.c-episode-slider__pagination`,
      type: `progressbar`
    },
    navigation: {
      prevEl: `.c-episode-slider__prev.c-episode-slider__btn`,
      nextEl: `.c-episode-slider__next.c-episode-slider__btn`
    },
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      568: {
        slidesPerView: 1
      }
    }
  }

  return (
    <View
      swiperProps={swiperProps}
      title={title}
      episodes={episodes}
    />
  )
}

EpisodeRoll.propTypes = {
  episodes: PropTypes.array.isRequired
}

EpisodeRoll.defaultProps = {
  title: `Recent Episodes`
}

export default EpisodeRoll
