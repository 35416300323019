import React from 'react'
import { Link } from 'gatsby'
import Image from 'Image'

import PlayButton from 'AudioPlayer/AudioPlayerUpdateTrackButton'

/**
 * Convert an HH:MM time length to minutes
 * @param  {String} duration
 * @return {String|Number}
 */
const formatEpisodeLength = (duration) => {
  // If the value is already in seconds, convert back to hh:mm:ss format
  if (!duration.includes(':')) {
    duration = new Date(parseInt(duration) * 1000).toISOString().substr(11, 8)
  }

  const time = duration.split(':')

  // handle 00:00 format
  if (time.length === 2) {
    return time[0]
  }

  // handle 00:00:00 format
  // If the time length doesn't go over an hour, just return the minutes
  if (time[0] === '00') {
    return time[1]
  } else {
    // If it does go over an hour, add the hours to the minutes
    const minutes = parseInt(time[1]) + (parseInt(time[0]) * 60)
    return minutes
  }
}

const EpisodeRollItem = ({ slug, title, track, show, date, duration, isPlaying }) => (
  <div
    className={`c-episode ${isPlaying ? `c-episode--is-current` : ``}`}
  >
    <figure
      className='c-episode__image u-img-full'
    >
      <Link
        to={slug}
      >
        <Image
          image={show.artwork}
          width={520}
          alt={`${show.title}'s artwork.`}
          className='u-width-1/1 u-position-absolute u-position-tl'
          sizes={[
            `${Image.breakpoint(568)} calc(100vw - 54px)`,
            `${Image.breakpoint(768)} calc(50vw - 37px)`,
            `${Image.breakpoint(1024)} calc((100vw - 74px) / 3)`,
            `${Image.breakpoint('container')} calc((100vw - 94px) / 5)`,
            '274px'
          ]}
          minWidth={100}
          lazyload
        />
      </Link>
    </figure>
    <div
      className='c-episode__body'
    >
      <PlayButton
        parentClassName='c-episode__play-button'
        iconClassName='u-color-grey'
        track={track}
      />
      <span
        className='c-episode__detail'
      >
        <span
          className='c-episode__show'
        >
          <Link
            className='u-color-white c-episode__link'
            to={show.slug}
          >
            {show.title}
          </Link>
        </span>
        <h4
          className='c-episode__title'
        >
          <Link
            className='u-color-smoke c-episode__link'
            to={slug}
          >
            {
              title.length > 25
                ? `${title.substring(0, 40)}...`
                : title
            }
          </Link>
        </h4>
      </span>
      <span
        className='c-episode__footer'
      >
        {
          duration &&
            <span
              className='c-episode__duration'
            >
              {`${formatEpisodeLength(duration)} mins`}
            </span>
        }
        <span
          className='c-episode__date'
        >
          {date}
        </span>
      </span>
    </div>
  </div>
)

export default EpisodeRollItem
