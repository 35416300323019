import React from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'

import useAudioPlayer from 'AudioPlayer/_useAudioPlayer'

import EpisodeRollItem from './EpisodeRollItem'

const EpisodeRollView = ({ title, episodes, swiperProps }) => {
  const { currentTrack } = useAudioPlayer()
  return (
    <section
      className='c-episode-slider'
    >
      <div
        className='o-container u-margin-bottom u-margin-bottom-large@md'
      >
        <h3
          className='c-episode-slider__title e-h4'
        >
          {title}
        </h3>
        <Swiper
          {...swiperProps}
        >
          {
            /* Remove any episodes which don't have a show, loop the episodes data */
            episodes.filter(episode => episode.show).map((episode, key) => {
              /* Deconstruct the episode object */
              const {
                title,
                audioUrl,
                audioDuration,
                publishDate,
                show,
                slug
              } = episode

              /* Create an object to hold each episode's audio data */
              const track = {
                url: audioUrl,
                duration: audioDuration,
                name: title,
                slug: slug,
                show: Object.assign(show.frontmatter, show.fields)
              }

              const isPlaying = (currentTrack && track.url === currentTrack.url)

              return (
                <article
                  className='c-episode-slider__item'
                  key={key}
                >
                  <EpisodeRollItem
                    slug={slug}
                    title={title}
                    track={track}
                    show={Object.assign(show.frontmatter, show.fields)}
                    date={publishDate}
                    duration={audioDuration}
                    isPlaying={isPlaying}
                  />
                </article>
              )
            })
          }
        </Swiper>
      </div>
    </section>
  )
}

EpisodeRollView.propTypes = {
  episodes: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export default EpisodeRollView
