/**
 * Renders a component with which to update the currently
 * playing audio.
 */

import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as PauseIcon } from 'assets/img/svg/pause.svg'
import { ReactComponent as PlayIcon } from 'assets/img/svg/play.svg'

import useAudioPlayer from './_useAudioPlayer'

const Icon = ({ isPlaying, className }) => (
  <span className={`${className || ``} o-disc-button o-fill-parent`}>
    { isPlaying && <PauseIcon /> }
    { !isPlaying && <PlayIcon /> }
  </span>
)

const AudioPlayerTrackUpdateButton = ({ track, parentClassName, iconClassName, children }) => {
  /* Get state and relevant functions from the context */
  const {
    handleTrackUpdate,
    currentTrack,
    isPlaying
  } = useAudioPlayer()

  return (
    <button
      onClick={() => handleTrackUpdate(track, !isPlaying)}
      className={`o-button-reset ${parentClassName || ``}`}
    >
      <span
        className='u-hide-visually'
      >
        {
          /* Check whether track exists and is playing */
          (isPlaying && track.url === currentTrack.url) ? `Pause` : `Play`
        }
      </span>
      <span
        className={`${children ? `u-dis-ib u-align-middle` : `o-fill-parent`}`}
      >
        <Icon
          isPlaying={isPlaying && track.url === currentTrack.url}
          className={iconClassName}
        />
      </span>
      {children}
    </button>
  )
}

AudioPlayerTrackUpdateButton.propTypes = {
  track: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    show: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired
  })
}

export default AudioPlayerTrackUpdateButton
